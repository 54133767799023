<template>
  <a-menu
    :selectedKeys="selectedKeys"
    :default-open-keys="['workplace']"
    mode="inline"
    theme="dark"
    class="menu-list"
    :openKeys.sync="openKeys"
  >
    <template v-for="{ key, icon, svgicon, title, submenus } in authMenus">
      <a-sub-menu v-if="submenus && submenus.length > 0" :key="key">
        <span slot="title">
          <icon-font v-if="icon" :type="icon" class="text-white"></icon-font>
          <span>{{ title }}</span>
        </span>

        <template
          v-for="{
            key: subKey,
            title: subTitle,
            submenus: thrmenus
          } in submenus"
        >
          <a-sub-menu v-if="thrmenus && thrmenus.length > 0" :key="subKey">
            <span slot="title">
              <span>{{ subTitle }}</span>
            </span>
            <a-menu-item
              v-for="{ key: subKey2, title: subTitle2 } in thrmenus"
              :key="subKey2"
              @click="handleMenuClick(subKey2)"
              class="sub-item"
            >
              <span>{{ subTitle2 }}</span>
            </a-menu-item>
          </a-sub-menu>

          <template v-else>
            <a-menu-item :key="subKey" @click="handleMenuClick(subKey)">
              <span>
                <span>{{ subTitle }}</span>
              </span>
            </a-menu-item>
          </template>
        </template>
      </a-sub-menu>
      <template v-else>
        <a-menu-item :key="key" @click="handleMenuClick(key)">
          <icon-font v-if="icon" :type="icon"></icon-font>
          <span>
            <icon-svg v-if="svgicon" :icon="svgicon" size="14px" />
            <span>{{ title }}</span>
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { SIDER_MENU_LIST } from '@/configs/sider-menu'
import { mapGetters } from 'vuex'
export default {
  name: 'GlobalSider',
  data() {
    return {
      // 当前选中的菜单项
      selectedKeys: ['1'],
      // 当前展开的 subMenu 项
      openKeys: ['1'],
      menus: [
        {
          key: 'workplace'
        },
        {
          key: 'publicManage',
          submenus: [
            {
              key: 'documentManage'
            },
            {
              key: 'messageManagement'
            }
          ]
        },
        {
          key: 'user',
          submenus: [
            {
              key: 'groupManage'
            },
            {
              key: 'userManage'
            },
            {
              key: 'roleManage'
            },
            {
              key: 'postManage'
            }
          ]
        },
        {
          key: 'basic',
          submenus: [
            {
              key: 'customer',
              submenus: [
                {
                  key: 'hospitalManage'
                },
                {
                  key: 'doctorManage'
                },
                {
                  key: 'speakerManage'
                },
                {
                  key: 'hospitalPcApp'
                },
                {
                  key: 'doctorPcApp'
                },
                {
                  key: 'speakerPcApp'
                },
                {
                  key: 'appModify'
                },
                {
                  key: 'dataMerge'
                },
                {
                  key: 'hospAppAnalysis'
                },
                {
                  key: 'ncCrmTermiClean'
                },
                {
                  key: 'hospModBatch'
                }
              ]
            },
            {
              key: 'product',
              submenus: [
                {
                  key: 'productManage'
                },
                {
                  key: 'productKeyInfoManage'
                },
                {
                  key: 'drugForm'
                }
              ]
            },
            {
              key: 'chnlArea'
            },
            {
              key: 'externalShippingCompany'
            },
            {
              key: 'genericDrugsPayAgre'
            },
            {
              key: 'businessManage'
            },
            {
              key: 'varietyBusinessLineManage'
            },
            {
              key: 'productExceptionManage'
            },
            {
              key: 'areaManage'
            },
            {
              key: 'synonymManage'
            }
          ]
        },
        {
          key: 'outerSpec',
          submenus: [
            {
              key: 'extCompShippingBill'
            },
            {
              key: 'extSpecBackBill'
            },
            {
              key: 'extVerificationBill'
            },
            {
              key: 'outerInventory'
            },
            {
              key: 'outerHospSpec'
            },
            {
              key: 'outerFlowBill'
            },
            {
              key: 'outerAllocateBill'
            },
            {
              key: 'outerBackFlowVerify'
            },
            {
              key: 'outerBusiFeeCalc'
            }
          ]
        },
        {
          key: 'settlement',
          submenus: [
            {
              key: 'saleTarget',
              submenus: [
                {
                  key: 'monthlySalesTarget'
                },
                {
                  key: 'provinceIndex'
                },
                {
                  key: 'indexMonthProportion'
                },
                {
                  key: 'hospitalSaleIndex'
                },
                {
                  key: 'monthlySalesTargetQuery'
                }
              ]
            },
            {
              key: 'chsDigitContract'
            },
            {
              key: 'chsClerkMonthInv'
            },
            {
              key: 'chsClearInv'
            },
            {
              key: 'chsClerkCostDetail'
            }
          ]
        },
        {
          key: 'nonsettlement',
          submenus: [
            {
              key: 'businessRefundContract'
            },
            {
              key: 'nonAcademicRefundContract'
            }
          ]
        },
        {
          key: 'newsettlement',
          submenus: [
            {
              key: 'partnerAgreementRefund'
            },
            {
              key: 'genericDrugsOpeningInventory'
            },
            {
              key: 'genericDrugsFlow'
            },
            {
              key: 'genericDrugsAlloc'
            },
            {
              key: 'genericDrugsChnlIndex'
            },
            {
              key: 'genericDrugsZg'
            },
            {
              key: 'genericDrugsChnlLs'
            },
            {
              key: 'genericDrugsDigitContr'
            },
            {
              key: 'genericDrugsDevBonusArchive'
            },
            {
              key: 'genericDrugsSettleNumCalc'
            },
            {
              key: 'genericDrugsFeeCalc'
            }
          ]
        },
        {
          key: 'sendManage',
          submenus: [
            {
              key: 'proofOfDelivery'
            }
          ]
        },
        {
          key: 'saleManage',
          submenus: [
            {
              key: 'salesArea'
            },
            {
              key: 'postDoctor'
            },
            {
              key: 'targetDoctorManage'
            },
            {
              key: 'visit'
            },
            {
              key: 'followUpVisit'
            },
            {
              key: 'buqianPcApp'
            },
            {
              key: 'outsideTime'
            },
            {
              key: 'targetHospApprove'
            }
          ]
        },
        {
          key: 'activityManage',
          submenus: [
            {
              key: 'activityApplication'
            },
            {
              key: 'activityReimbursement'
            },
            {
              key: 'activityAppModify'
            },
            {
              key: 'photoManage'
            },
            {
              key: 'photoManageSimQuery'
            },
            {
              key: 'activityPhotoRight'
            },
            {
              key: 'brandPresentation'
            },
            {
              key: 'activityType'
            },
            {
              key: 'datasControlByRole'
            },
            {
              key: 'activityAppQuery',
              submenus: [
                {
                  key: 'queryActivityApplication'
                },
                {
                  key: 'queryActivityReimbursement'
                },

                {
                  key: 'queryYFPayment'
                },
                {
                  key: 'queryDGPayment'
                },
                {
                  key: 'queryDSPayment'
                },
                {
                  key: 'jxBxQuery'
                },
                {
                  key: 'recAppQuery'
                }
              ]
            }
          ]
        },
        {
          key: 'activity',
          submenus: [
            {
              key: 'nonMeetingApp'
            },
            {
              key: 'nonMeetingAppQuery'
            }
          ]
        },
        {
          key: 'hcv',
          submenus: [
            {
              key: 'hcvAgencyDoc'
            },
            {
              key: 'hcvApplication'
            },
            {
              key: 'hcvWriteOff'
            },
            {
              key: 'hcvreport',
              submenus: [
                {
                  key: 'hcvApplicationQuery'
                },
                {
                  key: 'hcvWriteOffQuery'
                }
              ]
            }
          ]
        },
        {
          key: 'housePropertyManage',
          submenus: [
            {
              key: 'housePropertyArchive'
            },
            {
              key: 'rentHouseApp'
            },
            {
              key: 'houseRegister'
            },
            {
              key: 'houseDormitory'
            },
            {
              key: 'housePropertyArchiveModify'
            },
            {
              key: 'houseRentFeeCalc'
            },
            {
              key: 'housePaymentDG'
            },
            {
              key: 'housePaymentDS'
            },
            {
              key: 'report',
              submenus: [
                {
                  key: 'rentHouseAppQuery'
                },
                {
                  key: 'houseRegisterQuery'
                },
                {
                  key: 'houseDormitoryQuery'
                },
                {
                  key: 'housePaymentDGQuery'
                },
                {
                  key: 'housePaymentDSQuery'
                },
                {
                  key: 'housePropertyArchiveModifyQuery'
                }
              ]
            }
          ]
        },
        {
          key: 'flowdirect',
          submenus: [
            {
              key: 'flowToGrab'
            },
            {
              key: 'stockGrab'
            },
            {
              key: 'warehouseGrab'
            }
          ]
        },
        {
          key: 'costManage',
          submenus: [
            {
              key: 'costDeductCompensation'
            },
            {
              key: 'costSummary'
            },
            {
              key: 'localFeeApp'
            },
            {
              key: 'expenseReport'
            },
            {
              key: 'feeAppQuery'
            }
          ]
        },
        {
          key: 'menuManage',
          submenus: [
            {
              key: 'billHandedOver'
            },
            {
              key: 'proxyApprove'
            },
            {
              key: 'batchSync'
            },
            {
              key: 'menusManage'
            },
            {
              key: 'permissionManage'
            },
            {
              key: 'defDocListManage'
            },
            {
              key: 'defDocManage'
            },
            {
              key: 'shareManage'
            }
          ]
        }
      ]
    }
  },
  created() {
    const routes = this.$route.matched.slice(1)
    routes.forEach(({ name, meta }, index) => {
      if (index < routes.length - 1) {
        this.openKeys.push(name)
      } else {
        this.selectedKeys.push(meta.menuKey)
      }
    })
  },
  computed: {
    ...mapGetters(['usermenus']),
    authMenus() {
      let tpmenus = this.menus
      let ownmenus = tpmenus.filter(menu => {
        // debugger
        if (menu.submenus) {
          menu.submenus = menu.submenus.filter(submenu => {
            if (submenu.submenus) {
              submenu.submenus = submenu.submenus.filter(thrmenu => {
                return this.usermenus
                  ? this.usermenus.findIndex(usermenu => {
                      return usermenu.code === thrmenu.key
                    }) !== -1
                  : false
              })
              return submenu.submenus.length > 0
            } else {
              return this.usermenus
                ? this.usermenus.findIndex(usermenu => {
                    return usermenu.code === submenu.key
                  }) !== -1
                : false
            }
          })
          return menu.submenus.length > 0
        } else {
          return true
        }
      })
      return this.getAuthMenus(ownmenus)
    }
  },
  watch: {
    $route(nval) {
      const route = nval
      const { meta } = route
      if (meta.menuKey) {
        this.selectedKeys = []
        this.selectedKeys.push(meta.menuKey)
      }
    }
  },
  methods: {
    /**
     * 生成菜单列表
     * @param {Array} menus key 值列表，[{ key: ''},{ key: '', submenus: [ { key: '' } ] }]
     * @param {Array} menus 的配置数组，[{ key: '', title: '', route: ''} ]
     * @returns {Array} 菜单列表
     */
    getAuthMenus(menus, menuConfigs = SIDER_MENU_LIST) {
      return menus.map(({ key, submenus }) => {
        const menu = this.getMenuConfig(key, menuConfigs)
        const { title = key, icon, svgicon, submenus: submenuConfigs } = menu
        const baseMenu = { key, title, icon, svgicon }
        if (!submenus || !submenus.length) {
          return { ...baseMenu }
        }
        let authSubmenus = []
        if (submenuConfigs && submenuConfigs.length) {
          authSubmenus = this.getAuthMenus(submenus, submenuConfigs)
        }

        return { ...baseMenu, submenus: authSubmenus }
      })
    },

    /**
     * 获取 configs 中指定 key 的 config 对象
     * @param {String} key menu 的 key 值
     * @param {Array} configs 要查找的数组
     * @returns {Object} 指定 key 的 config，如果没有，则返回 {}
     */
    getMenuConfig(key, configs) {
      return configs.find(config => config.key === key) || {}
    },

    /**
     * 处理菜单点击事件
     * @param {String} routeName 要进入的路由名
     */
    handleMenuClick(routeName) {
      this.$router.push({ name: routeName })
    }

    // /**
    //  * @param {String} value 给menuKey赋值
    //  */
    // setMenuKey(value) {
    //   this.menuKey = value
    // }
  }
}
</script>

<style lang="less" scoped>
.menu-list {
  color: rgba(255, 255, 255);
  background: #3d4864;
}
/deep/ .ant-menu-item {
  font-weight: 600;
  color: #ffffff !important;
  line-height: 48px !important;
  height: 48px !important;
  &.ant-menu-item-selected {
    color: #ffffff !important;
    border-left: 2px solid #ffffff;
  }
}

/deep/ .ant-menu-submenu.ant-menu-submenu-inline > .ant-menu-submenu-title {
  font-weight: 600;
  color: #ffffff;
  line-height: 48px !important;
  height: 48px !important;
}

/deep/.ant-menu.ant-menu-inline.ant-menu-sub {
  background: #3d4864;
  box-shadow: none;
  & .sub-item {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}

/deep/.ant-menu-item.ant-menu-item-selected {
  background: #4587f4 !important;
  color: #ffffff !important;
}

/deep/.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #4587f4;
  color: #ffffff !important;
}

/deep/ .ant-menu-inline .ant-menu-item:after {
  border-right: 0px;
}
</style>
